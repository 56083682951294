import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/packages/docs/src/components/layout.tsx";
import { Fragment } from 'react';
import { HtmlAttribute } from '../components/attribute/html-attribute';
import { ApiAttribute } from '../components/attribute/api-attribute';
import { ObjectAttribute } from '../components/attribute/object-attribute';
import { SubType } from '../components/attribute/sub-type';
import { P, Ul, Code } from '../components/attribute/common';
import { ChildAttributes } from '../components/attribute/child-attributes';
import { Signature } from '../components/attribute/signature';
import { Note } from '../components/note';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`title: Reference
description: Reference docs for using Flexport Website Fast Tags.`}</p>



    <h2 {...{
      "id": "reference",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#reference",
        "aria-label": "reference permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Reference`}</h2>
    <h2 {...{
      "id": "web-components",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#web-components",
        "aria-label": "web components permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Web Components`}</h2>
    <p>{`Flexport offers pre-built web components for simple and easy fast tag integration.`}</p>
    <hr />
    <h3 {...{
      "id": "deliverr-tag",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#deliverr-tag",
        "aria-label": "deliverr tag permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`deliverr-tag`}</h3>
    <p>{`The `}<strong parentName="p">{`deliverr-tag`}</strong>{` web component adds a fast tag to your page when a visitor comes from a zip code that is eligible for fast shipping for the matching skus.`}</p>
    <h4 {...{
      "id": "deliverr-tag-attributes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#deliverr-tag-attributes",
        "aria-label": "deliverr tag attributes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Attributes`}</h4>
    <HtmlAttribute name="data-dskus" accepts="string[]" description={<p>
      Accepts a JSON serializable array of SKUS as they are identified on the
      Flexport platform. View your inventory in the{' '}
      <a href="https://portal.flexport.com">Flexport seller portal</a> for
      details.
    </p>} namespace="deliverr-tag" mdxType="HtmlAttribute" />
    <HtmlAttribute name="data-skus" accepts="string[]" description="Accepts a JSON serializable array of SKUS associated to products in your seller account." namespace="deliverr-tag" mdxType="HtmlAttribute" />
    <HtmlAttribute name="data-sellerid" accepts="string" description={<p>Your seller id as provided to you by Flexport</p>} namespace="deliverr-tag" mdxType="HtmlAttribute" />
    <HtmlAttribute name="data-use-service-level" accepts="min, max" defaults="max" description={<div>
      <P mdxType="P">
        Sets the method of identifying the level of service for a set of
        products when difference service levels are available.
      </P>
      <Ul mdxType="Ul">
        <li>max will display the slowest service level available</li>
        <li>min will display the shortest service level available</li>
      </Ul>
    </div>} namespace="deliverr-tag" mdxType="HtmlAttribute" />
    <ApiAttribute name="data-cart-size" type="number" defaults="0" description={<p>
      The total dollar amount of items in the cart. Recommended when using cart
      minimums.
    </p>} namespace="deliverr-tag" mdxType="ApiAttribute" />
    <ApiAttribute name="data-price" type="number" defaults="0" description={<p>
      The dollar amount of the product assigned to the fast tag. Recommended
      when using cart minimums.
    </p>} namespace="deliverr-tag" mdxType="ApiAttribute" />
    <hr />
    <h3 {...{
      "id": "deliverr-tag-extended",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#deliverr-tag-extended",
        "aria-label": "deliverr tag extended permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`deliverr-tag-extended`}</h3>
    <p>{`The `}<strong parentName="p">{`deliverr-tag-extended`}</strong>{` web component adds a fast tag to your page when a visitor comes from a zip code that is eligible for fast shipping for the matching It also includes a zip code form that allows a visitor to manually enter their zip code rather than solely relying on Flexport's zip code service.`}</p>
    <h4 {...{
      "id": "deliverr-tag-extended-attributes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#deliverr-tag-extended-attributes",
        "aria-label": "deliverr tag extended attributes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Attributes`}</h4>
    <HtmlAttribute name="data-dskus" accepts="string[]" description={<p>
      Accepts a JSON serializable array of SKUS as they are identified on the
      Flexport platform. View your inventory in the{' '}
      <a href="https://portal.flexport.com">Flexport seller portal</a> for
      details.
    </p>} namespace="deliverr-tag-extended" mdxType="HtmlAttribute" />
    <HtmlAttribute name="data-skus" accepts="string[]" description="Accepts a JSON serializable array of SKUS associated to products in your seller account." namespace="deliverr-tag-extended" mdxType="HtmlAttribute" />
    <HtmlAttribute name="data-sellerid" accepts="string" description={<p>Your seller id as provided to you by Flexport.</p>} namespace="deliverr-tag-extended" mdxType="HtmlAttribute" />
    <HtmlAttribute name="data-use-service-level" accepts="min, max" defaults="max" description={<div>
      <P mdxType="P">
        Sets the method of identifying the level of service for a set of
        products when difference service levels are available.
      </P>
      <Ul mdxType="Ul">
        <li>max will display the slowest service level available</li>
        <li>min will display the shortest service level available</li>
      </Ul>
    </div>} namespace="deliverr-tag-extended" mdxType="HtmlAttribute" />
    <ApiAttribute name="data-cart-size" type="number" defaults="0" description={<p>
      The total dollar amount of items in the cart. Recommended when using cart
      minimums.
    </p>} namespace="deliverr-tag-extended" mdxType="ApiAttribute" />
    <ApiAttribute name="data-price" type="number" defaults="0" description={<p>
      The dollar amount of the product assigned to the fast tag. Recommended
      when using cart minimums.
    </p>} namespace="deliverr-tag-extended" mdxType="ApiAttribute" />
    <hr />
    <h2 {...{
      "id": "deliverr-fast-tags-global",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#deliverr-fast-tags-global",
        "aria-label": "deliverr fast tags global permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`DeliverrFastTags Global`}</h2>
    <p>{`An object storing details about the Flexport Fast Tag library.`}</p>
    <h3 {...{
      "id": "app-config",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#app-config",
        "aria-label": "app config permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`appConfig: App Configuration`}</h3>
    <p>{`The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`appConfig`}</code>{` property of the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`DeliverrFastTags`}</code>{` global represents non default behavior applied to the whole application.`}</p>
    <ApiAttribute name="sellerId" type="string" required="optional" description={<p>
      The seller id as used by Flexport. Can be found in Flexport's{' '}
      <a href="https://portal.flexport.com">seller portal</a>
    </p>} namespace="app-config" mdxType="ApiAttribute" />
    <ApiAttribute name="cartMinimum" type="boolean" required="optional" description={<p>
      Toggles use of the cart minimum feature. If set to <Code mdxType="Code">true</Code>, the{' '}
      <a href="#app-config-seller-id">sellerId</a> must also be set. If not set,
      cart minimums will be <b>inactive</b>
    </p>} namespace="app-config" mdxType="ApiAttribute" />
    <hr />
    <h2 {...{
      "id": "api-client",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#api-client",
        "aria-label": "api client permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`API Client`}</h2>
    <p>{`The Flexport Website Fast Tags library includes an http client for connecting to the Flexport API. This is made available through the `}<Code mdxType="Code">{`window`}</Code>{` object on `}<Code mdxType="Code">{`window.DeliverrApi`}</Code>{`.`}</p>
    <hr />
    <h3 {...{
      "id": "getshippingoptions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#getshippingoptions",
        "aria-label": "getshippingoptions permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`getShippingOptions`}</h3>
    <Signature text="(options: Options) => Promise<ShippingOptions>" mdxType="Signature" />
    <h4 {...{
      "id": "get-shipping-options-parameters",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#get-shipping-options-parameters",
        "aria-label": "get shipping options parameters permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Options`}</h4>
    <ApiAttribute name="destination" type="Address" required="required" description={<P mdxType="P">An Address object that identifies the address being shipped to.</P>} namespace="get-shipping-options-parameters" mdxType="ApiAttribute">
  <ObjectAttribute name="Address" mdxType="ObjectAttribute">
    <Fragment mdxType="Fragment">
      <ApiAttribute name="street1" type="string" required="required" description="The address of the location being shipped to." example="760 Market Street" namespace="get-shipping-options-parameters-address" mdxType="ApiAttribute" />
      <ApiAttribute name="street2" type="string" required="optional" description="Alternative address information of the location being shipped to." example="8th Floor" namespace="get-shipping-options-parameters-address" mdxType="ApiAttribute" />
      <ApiAttribute name="zip" type="string" required="required" description="The zip code of the location being shipped to" example="94102" namespace="get-shipping-options-parameters-address" mdxType="ApiAttribute" />
      <ApiAttribute name="city" type="string" required="required" description="The city of the location being shipped to" example="San Francisco" namespace="get-shipping-options-parameters-address" mdxType="ApiAttribute" />
      <ApiAttribute name="state" type="string" required="required" description="The state of the location being shipped to." example="CA" namespace="get-shipping-options-parameters-address" mdxType="ApiAttribute" />
      <ApiAttribute name="country" type="string" required="required" description="The country of the location being shipped to. Flexport currently only supports 'US'." example="US" namespace="get-shipping-options-parameters-address" mdxType="ApiAttribute" />
    </Fragment>
  </ObjectAttribute>
    </ApiAttribute>
    <ApiAttribute name="skus" required="optional" type="string[]" description={<p>
      The array of SKUs for the product(s) being checked. If used,{' '}
      <Code mdxType="Code">options</Code> must also have a <Code mdxType="Code">sellerId</Code> property.
    </p>} namespace="get-shipping-options-parameters" mdxType="ApiAttribute" />
    <ApiAttribute name="sellerId" required="optional" type="string" description={<p>
      Your seller id as provided to you by Flexport. If used,{' '}
      <Code mdxType="Code">options</Code> must also have a <Code mdxType="Code">skus</Code> property.
    </p>} namespace="get-shipping-options-parameters" example="deliverr-seller" mdxType="ApiAttribute" />
    <ApiAttribute name="dskus" type="string[]" required="optional" description={<p>The array of Flexport provided SKUS for the product(s) being checked.</p>} namespace="get-shipping-options-parameters" mdxType="ApiAttribute" />
    <Note mdxType="Note">
  <div>One of:</div>
  <ul>
    <li>`skus` and `sellerId`</li>
    <li>`dskus`</li>
  </ul>
  <div>must be passed in.</div>
    </Note>
    <h4 {...{
      "id": "get-shipping-options-return-value",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#get-shipping-options-return-value",
        "aria-label": "get shipping options return value permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ShippingOptions`}</h4>
    <ApiAttribute name="name" type="string" description={<p>The name of the service level provided for that. If you're using <a href="https://docs.logistics-api.flexport.com/2023-10/tag/Orders#operation/CreateOrder202307">logistics API's create order API</a>, pass this field into `ship method name` </p>} namespace="get-shipping-options-parameters" mdxType="ApiAttribute" />
    <ApiAttribute name="shippingDays" type="number" description={<p>The number of days it takes to ship the product</p>} namespace="get-shipping-options-parameters" mdxType="ApiAttribute" />
    <ApiAttribute name="arrivesBy" type="string" description={<p>
      The confirmed arrival date for the product if ordered by within the
      guarantee period.
    </p>} namespace="get-shipping-options-parameters" mdxType="ApiAttribute" />
    <ApiAttribute name="code" type="string" description={<p>An identifier for the service level being offered for that product</p>} namespace="get-shipping-options-parameters" mdxType="ApiAttribute" />
    <ApiAttribute name="note" type="string" required="optional" description={<div>
      <p>
        In some cases an optional note field is returned in the response. This
        occurs when:
      </p>
      <ul>
        <li>The customer address is a PO Box</li>
        <li>
          The cart contains a mix of products with different shipping speeds
        </li>
      </ul>
    </div>} mdxType="ApiAttribute" />
    <h4 {...{
      "id": "example",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#example",
        "aria-label": "example permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example`}</h4>
    <div {...{
      "className": "gatsby-code-button-container",
      "data-toaster-id": "",
      "data-toaster-class": "gatsby-code-button-toaster",
      "data-toaster-text-class": "gatsby-code-button-toaster-text",
      "data-toaster-text": "",
      "data-toaster-duration": "3500",
      "onClick": "copyToClipboard(`{\n    \"name\": \"Free NextDay Delivery\",\n    \"shippingDays\": 1,\n    \"arrivesBy\": \"Arrives by Tuesday, October 13th\",\n    \"code\": \"Deliverr-1-Day\"\n}`, ``)"
    }}>{`
              `}<div parentName="div" {...{
        "className": "gatsby-code-button",
        "data-tooltip": ""
      }}>{`
                `}<svg parentName="div" {...{
          "className": "gatsby-code-button-icon",
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24"
        }}><path parentName="svg" {...{
            "fill": "none",
            "d": "M0 0h24v24H0V0z"
          }}></path><path parentName="svg" {...{
            "d": "M16 1H2v16h2V3h12V1zm-1 4l6 6v12H6V5h9zm-1 7h5.5L14 6.5V12z"
          }}></path></svg>{`
              `}</div>{`
            `}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token string-property property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Free NextDay Delivery"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token string-property property"
          }}>{`"shippingDays"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token string-property property"
          }}>{`"arrivesBy"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Arrives by Tuesday, October 13th"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token string-property property"
          }}>{`"code"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Deliverr-1-Day"`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      